import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => {
  
  return (
    <Layout>

      <SEO 
        title="Home"
      />

      <div className="page-heading-box">
        <h1>HOME</h1>
      </div>
      
      
    </Layout>
  )
}


export default IndexPage;